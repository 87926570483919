import './App.css';
import bg from './images/bg.jpg';
import { useEffect, useRef, useState } from 'react';
import Modal from './components/Modal';
import Button from './components/Button';
import { MD5 } from 'crypto-js';

function App() {
  const [scale, setScale] = useState(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [customer, setCustomer] = useState('');
  const [course, setCourse] = useState('');
  const [count, setCount] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [amount, setAmount] = useState('');
  const [source, setSource] = useState('');
  const [invitedBy, setInvitedBy] = useState('');
  const [teacher, setTeacher] = useState('');
  const [order, setOrder] = useState('');
  const [goal, setGoal] = useState('');
  const [progress, setProgress] = useState('');
  const [date, setDate] = useState('');
  const [isHidden, setIsHidden] = useState(true);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');


    const img = new Image();

    img.onload = function () {
      const height = this.naturalHeight;
      const width = this.naturalWidth;
      const targetWidth = 640;
      const scale = height / width;
      const targetHeight = targetWidth * scale;
      setScale(scale);
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      ctx.drawImage(this, 0, 0, targetWidth, targetHeight);

      setImage({
        image: img,
        targetWidth,
        targetHeight
      });
    };

    img.src = bg;
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const middle = image ? image.targetWidth / 2 : 0;

    image && ctx.drawImage(image.image, 0, 0, image.targetWidth, image.targetHeight)

    drawText(ctx, '恭喜', [middle, 364], {
      size: 42,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, name, [middle, 451], {
      size: 56,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, `${type} ${customer} ${course} ${count}次课`, [middle, 515], {
      size: 24,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, paymentType, [114, 655], {
      size: 42,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, amount, [middle, 655], {
      size: 72,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, '元', [550, 655], {
      size: 42,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, '感谢', [middle, 715], {
      size: 24,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, '来源：' + source, [middle, 757], {
      size: 24,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, '邀约：' + invitedBy, [middle, 800], {
      size: 24,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, `线下体验：${teacher}老师精彩授课`, [middle, 848], {
      size: 24,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, `今天第 ${order} 单`, [middle, 928], {
      size: 64,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, `#本月目标 ${goal} 个新签#`, [middle, 980], {
      size: 20,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, `现已完成 ${progress} 个`, [middle, 1010], {
      size: 20,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })

    drawText(ctx, date, [middle, 1060], {
      size: 20,
      fontWeight: 'bold',
      color: 'rgb(60, 34, 7)'
    })
  }, [image, name, type,
    customer, course, count,
    paymentType, amount,
    source, invitedBy, teacher,
    order, goal, progress, date])


  function drawText(ctx, text = '', position = [0, 0], {
    size = 16,
    font = 'sans-serif',
    color = '#000000',
    fontWeight = 'normal',
    textAlign = 'center'
  } = {}) {
    let fontStyle = `${fontWeight} ${size}px ${font}`;
    ctx.fillStyle = color;
    ctx.textAlign = textAlign;
    ctx.font = fontStyle;
    ctx.fillText(text, +position[0], +position[1])
  }

  function handleX(event) {
    const x = event.target.value;
    setX(x)
  }

  function handleY(event) {
    const y = event.target.value;
    console.log({ y })
    setY(y)
  }

  function handleConfirm(event) {
    setIsHidden(true);
    const url = "https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key=cff30144-29fc-4fa5-a939-ee4faf0236f2"
    const data = {
      "msgtype": "text",
      "text": {
          "content": "hello world from test."
      }
    }

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }

  return (
    <div className="App">
      <header className="title text-center my-8">
        喜报生成器
      </header>
      <main className="flex mx-auto my-8 max-w-3xl">
        <div className="mr-auto">
          {/* <div className="py-2">
            <div>
              <label for="x">x: {x}</label>
              <input
                type="range"
                min="0"
                max="1000"
                name="x" onChange={handleX} className="bg-gray-100" />
            </div>
            <div>
              <label for="y">y: {y}</label>
              <input
                type="range"
                min="0"
                max="1000"
                name="y" onChange={handleY} className="bg-gray-100" />
            </div>
          </div> */}
          <div className="py-2">
            <label for="name">成交顾问老师: </label>
            <input
              onChange={e => setName(e.target.value) }
              name="name" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="type">类型: </label>
            <select
              onChange={e => setType(e.target.value)}
              name="type" className="bg-gray-100" >
                <option value="">--选择--</option>
                <option value="新签">新签</option>
                <option value="续费">续费</option>
                <option value="口碑">口碑</option>
            </select>
          </div>
          <div className="py-2">
            <label for="customer">学生名字: </label>
            <input
              onChange={e => setCustomer(e.target.value)}
              name="customer" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="course">课程: </label>
            <select
              onChange={e => setCourse(e.target.value)}
              name="course" className="bg-gray-100" >
                <option value="">--选择--</option>
                <option value="大颗粒">大颗粒</option>
                <option value="WeDo">WeDo</option>
                <option value="Spike">Spike</option>
                <option value="C++">C++</option>
              </select>
          </div>
          <div className="py-2">
            <label for="count">课程次数: </label>
            <input
              onChange={e => setCount(e.target.value)}
              type="number"
              name="count" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="payment-type">付款类型: </label>
            <select
              onChange={e => setPaymentType(e.target.value)}
              name="payment-type" className="bg-gray-100" >
                <option value="">--选择--</option>
                <option value="全款">全款</option>
                <option value="定金">定金</option>
                <option value="分期">分期</option>
                </select>
          </div>
          <div className="py-2">
            <label for="amount">金额: </label>
            <input
              onChange={e => setAmount(e.target.value)}
              name="amount" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="source">来源: </label>
            <input
              onChange={e => setSource(e.target.value)}
              name="source" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="invited-by">邀约: </label>
            <input
              onChange={e => setInvitedBy(e.target.value)}
              name="invited-by" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="teacher">体验课老师: </label>
            <input
              onChange={e => setTeacher(e.target.value)}
              name="teacher" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="order">今日第几单: </label>
            <input
              type="number"
              onChange={e => setOrder(e.target.value)}
              name="order" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="goal">本月目标: </label>
            <input
              type="number"
              onChange={e => setGoal(e.target.value)}
              name="goal" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="progress">本月已完成: </label>
            <input
              type="number"
              onChange={e => setProgress(e.target.value)}
              name="progress" className="bg-gray-100" />
          </div>
          <div className="py-2">
            <label for="date">日期: </label>
            <input
              type='date'
              onChange={e => setDate(e.target.value)}
              name="date" className="bg-gray-100" />
          </div>
          {/* <Button onClick={() => setIsHidden(false)}>发送喜报到企业群</Button> */}
        </div>
        <div className="w-1/2">
          <canvas className="w-full h-full border-solid border-4" ref={canvasRef} />
        </div>
      </main>
      <Modal 
        title="发送喜报到群"
        className={isHidden? 'hidden' : ''}
        onClose={() => setIsHidden(true)}
        onConfirm={handleConfirm}
        >向企业微信群发送喜报</Modal>
    </div>
  );
}

export default App;
