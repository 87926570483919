import { CloseCircleOutlined } from '@ant-design/icons';

function Modal(props) {
    const { children, title, onClose, onConfirm, className } = props;

    return (
        <div className={`${className} fixed top-1/2 left-1/2 transform -translate-x-1/2 divide-y
        w-60 overflow-hidden
        bg-white
        ring-blue-800 ring-2 rounded`}>
            <div className="flex 
                font-bold
                items-center px-4 py-2 align-middle">
                <div className="">{title}</div>
                <CloseCircleOutlined onClick={onClose} className="ml-auto cursor-pointer" />
            </div>
            <div className="px-4 py-4">{children}</div>
            <div className="flex">
                <button className="flex-1 px-4 py-2 bg-gray-100"
                    onClick={onClose}
                >关闭</button>
                <button className="flex-1 px-4 py-2 bg-blue-100 text-blue-800"
                    onClick={onConfirm}
                >确认</button>
            </div>
        </div>
    )
}


export default Modal